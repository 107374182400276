<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <h3 class="ml-1 mb-4 mt-4">Configurar establecimiento de comercio</h3>
      </v-col>
    </v-row>
    <v-form
      ref="formConfig"
      v-model="valid"
      lazy-validation
    >
      <v-row justify="center">
        <v-col cols="12" md="2" lg="2">
          <v-select
            label="debug"
            outlined
            dense
            :items="[
              'activo',
              'inactivo'
            ]"
            :rules="[rules.required]"
            v-model="config.debug"
          ></v-select>
        </v-col>
        <v-col cols="12" md="2" lg="2">
          <v-select
            label="PRODUCCION"
            outlined
            dense
            :items="[
              'activo',
              'inactivo'
            ]"
            :rules="[rules.required]"
            v-model="config.produccion"
          ></v-select>
        </v-col>
        <v-col cols="12" md="8" lg="8">
          <v-text-field
            label="URLACTION"
            outlined
            dense
            :rules="[rules.required]"
            v-model="config.urlaction"
          ></v-text-field>
        </v-col>
      </v-row>
      <!-- <v-row justify="center">
        <v-col cols="12" md="4" lg="4">
          <v-select
            label="ACCIÓN RECIBIDO AUTOMÁTICO"
            outlined
            dense
            item-text="value"
            item-value="id"
            :items="[
              { id: 'ACTIVA', value: 'activo' },
              { id: 'INACTIVA', value: 'inactivo' }
            ]"
            :rules="[rules.required]"
            v-model="config.accion_recivido_atumatica"
          ></v-select>
        </v-col>
        <v-col cols="12" md="4" lg="4">
          <v-select
            label="ACCIÓN BIEN O SERVICIO AUTOMÁTICO"
            outlined
            dense
            item-text="value"
            item-value="id"
            :items="[
              { id: 'ACTIVA', value: 'activo' },
              { id: 'INACTIVA', value: 'inactivo' }
            ]"
            :rules="[rules.required]"
            v-model="config.accion_bien_servicio_atumatica"
          ></v-select>
        </v-col>
        <v-col cols="12" md="4" lg="4">
          <v-select
            label="ACEPTACIÓN EXPRESA AUTOMÁTICO"
            outlined
            dense
            item-text="value"
            item-value="id"
            :items="[
              { id: 'ACTIVA', value: 'activo' },
              { id: 'INACTIVA', value: 'inactivo' }
            ]"
            :rules="[rules.required]"
            v-model="config.accion_aceptacion_expresa_atumatica"
          ></v-select>
        </v-col>
      </v-row> -->
      <v-row justify="center">
        <v-col cols="12" md="3" lg="3">
          <v-select
            label="ACCIÓN RECIBIDO AUTOMÁTICO"
            outlined
            dense
            item-text="value"
            item-value="id"
            :items="[
              { id: 'ACTIVA', value: 'activo' },
              { id: 'INACTIVA', value: 'inactivo' }
            ]"
            :rules="[rules.required]"
            v-model="config.accion_recivido_atumatica"
          ></v-select>
        </v-col>
        <v-col cols="12" md="3" lg="3">
          <v-text-field
            label="SOAPSERVICE"
            outlined
            dense
            :rules="[rules.required]"
            v-model="config.soapservice"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4" lg="4">
          <v-text-field
            label="ruta_archivos"
            outlined
            dense
            :rules="[rules.required]"
            v-model="config.ruta_archivos"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="2" lg="2">
          <v-text-field
            label="DocumentTypeCode"
            outlined
            dense
            :rules="[rules.required]"
            v-model="config.DocumentTypeCode"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6" lg="6">
          <v-text-field
            label="SOAPENDPOINTURL_PRODUCTION"
            outlined
            dense
            :rules="[rules.required]"
            v-model="config.soapendpointurl_produccion"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6" lg="6">
          <v-text-field
            label="SOAPENDPOINTURL_HABILITACION"
            outlined
            dense
            :rules="[rules.required]"
            v-model="config.soapendpointurl_habilitacion"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="12" md="1" lg="1">
          <v-text-field
            label="pin"
            outlined
            dense
            :rules="[rules.required]"
            v-model="config.pin"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4" lg="4">
          <v-text-field
            label="testid"
            outlined
            dense
            :rules="[rules.required]"
            v-model="config.testid"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4" lg="4">
          <v-text-field
            label="softwareid"
            outlined
            dense
            :rules="[rules.required]"
            v-model="config.softwareid"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="3" lg="3">
          <v-select
            label="PERMITIR CONTADO"
            outlined
            dense
            item-text="value"
            item-value="id"
            :items="[
              { id: 'ACTIVO', value: 'activo' },
              { id: 'INACTIVO', value: 'inactivo' }
            ]"
            :rules="[rules.required]"
            v-model="config.permitir_contado"
          ></v-select>
        </v-col>
      </v-row>
    </v-form>
    <v-row justify="end" class="mb-2 mr-2">
      <v-btn
        depressed
        dense
        outlined
        color="primary"
        class="mr-2"
        @click="$router.back()"
      >Cancelar</v-btn>
      <v-btn
        depressed
        dense
        color="primary"
        @click="saveUpdate"
      >Guardar</v-btn>
    </v-row>
  </v-container>
</template>
<script>
import eventBus from '@/boot/eventBus';
import { mapGetters } from 'vuex'
import { POST } from '@/boot/http';

const defaultConfig = () => JSON.parse(JSON.stringify({
  debug: "",
  pin: '',
  softwareid: '',
  testid: '',
  urlaction:'',
  produccion:'',
  soapservice:'',
  ruta_archivos: '',
  DocumentTypeCode: '',
  soapendpointurl_produccion: '',
  soapendpointurl_habilitacion: '',
  accion_recivido_atumatica: 'INACTIVA',
  accion_bien_servicio_atumatica: 'INACTIVA',
  accion_aceptacion_expresa_atumatica: 'INACTIVA'
}))

export default {
  name: 'ConfiguracionEstablecimientoComercio',
  data: () => ({
    config: defaultConfig(),
    globalConfig: null,
    rules: {
      required: value => !!value || 'Requerido'
    },
    valid: true
  }),
  mounted () {
    const id = this.$route.params.ec_id
    if (id !== undefined || id !== null ) {
      this.getConfig(id)
      // console.log(id);
    }
  },
  methods: {
    getConfig (establecimiento_id) {
      POST({
        url: 'procesosdianconfig/query',
        data: {
          p_datajson: {
            establecimiento_id
          },
          p_opc: 'GET_ID',
          p_auditoriajson: { usuario_id: this.getUserData.id }
        }
      }).then(data => {
        if (data.data.status.status === 'ok') {
          if (data.data.dato.id) {
            this.globalConfig = data.data.dato
            this.config = data.data.dato.data
            // console.log(this.globalConfig, this.config);
          } else {
            this.globalConfig = { establecimientocomercio_id: this.$route.params.ec_id }
            this.config = defaultConfig()
          }
          // eventBus.$emit('modal', { value: true, title: 'Exito!', text: data.data.status.mensaje, color: 'primary' })
        } else {
          eventBus.$emit('modal', { value: true, title: 'Error', text: data.data.status.mensaje, color: 'error' })
        }
      }).catch(error => {
        eventBus.$emit('modal', { value: true, title: 'Error', text: error.message, color: 'error' })
      })
    },
    saveUpdate () {
      const valid = this.$refs.formConfig.validate()
      if (valid) {
        const p_opc = this.globalConfig.id !== undefined ? 'UPDATE' : 'INSERT'
        POST({
          url: 'procesosdianconfig/dao',
          data: {
            p_datajson: {
              data: this.config,
              establecimientocomercio_id: this.globalConfig.establecimientocomercio_id,
              id: this.globalConfig.id
            },
            p_opc,
            p_auditoriajson: { usuario_id: this.getUserData.id }
          }
        }).then(data => {
          if (data.data.status.status === 'ok') {
            eventBus.$emit('modal', { value: true, title: 'Exito!', text: data.data.status.mensaje, color: 'primary' })
          } else {
            eventBus.$emit('modal', { value: true, title: 'Error', text: data.data.status.mensaje, color: 'error' })
          }
        }).catch(error => {
          eventBus.$emit('modal', { value: true, title: 'Error', text: error.message, color: 'error' })
        })
      }
    }
  },
  computed: { ...mapGetters(['getEstablecimientosComercio', 'getUserData']) },
}
</script>